<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-dialog v-model="dialog" width="1000" scrollable>
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            <span class="hidden-md-and-up">Edit IAR</span>
            <span class="hidden-sm-and-down">Edit Inspection And Acceptance Report</span>
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" text @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text style="max-height: 800px;">
            <v-container>
            <v-form @submit.prevent ref="Formref">
            <v-row class="mt-4">
              <v-col cols="12" sm="6">
                <v-text-field
                    dense
                    v-model="poData.SupplierName"
                    label="Supplier Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    dense
                    v-model="poData.InvoiceNo"
                    label="Invoice No."
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-menu
                    dense
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="poData.DateInvoice"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="poData.DateInvoice"
                        label="Invoice Date"
                        readonly
                        v-bind="attrs"
                        multiple
                        chips
                        small-chips
                        v-on="on"
                      >
                      <template
                      slot='selection'
                      slot-scope='data'>
                      <v-chip
                      @click="remove(data.item, 'invoice')"> 
                      {{data.item}}
                      </v-chip>
                      </template>
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="poData.DateInvoice"
                      multiple
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(poData.DateInvoice)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                    dense
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    :return-value.sync="poData.DateDelivered"
                    transition="scale-transition"
                    offset-y
                    class=""
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="poData.DateDelivered"
                        label="Date Delivered"
                        readonly
                        v-bind="attrs"
                        multiple
                        chips
                        small-chips
                        v-on="on"
                      >
                      <template
                      slot='selection'
                      slot-scope='data'>
                      <v-chip
                      @click="remove(data.item, 'delivered')"> 
                      {{data.item}}
                      </v-chip>
                      </template>
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="poData.DateDelivered"
                      no-title
                      multiple
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu4 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu4.save(poData.DateDelivered)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
              </v-col>
            </v-row>
            <v-row class="mt-n4">
              <v-col cols="12" sm="6">
                <div class="font-weight-bold font-size-18">Inspection</div>
                  <v-divider class="mb-5"></v-divider>
                  <v-checkbox
                    v-model="poData.IsInspected"
                    color="green darken-2"
                  >
                    <template v-slot:label>
                      <div>Inspected</div>
                    </template>
                  </v-checkbox>
                <v-menu
                    dense
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="poData.DateInspectedStart"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="poData.DateInspectedStart"
                        :rules="[formRules.required]"
                        label="Date Inspected (Start)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="poData.DateInspectedStart"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(poData.DateInspectedStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <br />
                <v-menu
                    dense
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :return-value.sync="poData.DateInspectedEnd"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="poData.DateInspectedEnd"
                        :rules="[formRules.required]"
                        label="Date Inspected (End)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="poData.DateInspectedEnd"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu3 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu3.save(poData.DateInspectedEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
              </v-col>

              <v-col cols="12" sm="6">
                <div class="font-weight-bold font-size-18">Acceptance</div>
                  <v-divider class="mb-5"></v-divider>
                  <v-radio-group row v-model="poData.Status">
                    <v-radio
                      label="Complete Delivery"
                      color="green darken-2"
                      value="Complete"
                    ></v-radio>

                    <v-radio
                      label="Partial Delivery"
                      color="green darken-2"
                      value="Partial"
                    ></v-radio>
                  </v-radio-group>
                  <v-menu
                    dense
                    ref="menu5"
                    v-model="menu5"
                    :close-on-content-click="false"
                    :return-value.sync="poData.DateReceivedStart"
                    transition="scale-transition"
                    offset-y
                    class="mb-10"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="poData.DateReceivedStart"
                        :rules="[formRules.required]"
                        label="Date Received (Start)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="poData.DateReceivedStart"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu5 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu5.save(poData.DateReceivedStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  <br />
                  <v-menu
                    dense
                    ref="menu6"
                    v-model="menu6"
                    :close-on-content-click="false"
                    :return-value.sync="poData.DateReceivedEnd"
                    transition="scale-transition"
                    offset-y
                    class="mb-10"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="poData.DateReceivedEnd"
                        :rules="[formRules.required]"
                        label="Date Received (End)"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="poData.DateReceivedEnd"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu6 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu6.save(poData.DateReceivedEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
              </v-col>
              <v-col cols="12">
                  <v-autocomplete
                    v-model="poData.RecieverUserID"
                    item-text="name"
                    item-value="UserID"
                    :rules="[formRules.required]"
                    :items="users"
                    dense
                    class=""
                    label="Select Receiver"
                  >
                  </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="itemList"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <span class="green--text text--darken-2"
                        >List Of Items</span
                      >
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <td>
                          <v-edit-dialog
                            :return-value.sync="item.StockPropertyNo"
                            large
                          >
                            {{ item.StockPropertyNo }}
                            <template v-slot:input>
                              <v-textarea v-model="item.StockPropertyNo"> </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                            {{ item.ItemName }}
                        </td>
                        <td style="max-width: 200px">
                          <v-edit-dialog
                            large
                            :return-value.sync="item.ItemDescription"
                          >
                            {{ item.ItemDescription }}
                            <template v-slot:input>
                              <v-textarea v-model="item.ItemDescription">
                              </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>{{ item.UnitMeasureDescription }}</td>
                        <td>
                          <v-edit-dialog
                            large
                            :return-value.sync="item.UnitCost"
                          >
                            ₱ {{ formatPrice(item.UnitCost) }}
                            <template v-slot:input>
                              <v-textarea v-model="item.UnitCost"> </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <v-edit-dialog
                            large
                            :return-value.sync="item.Qty"
                          >
                            <span
                              :class="
                                parseInt(item.OverAllQty) <
                                parseInt(item.Qty) || parseInt(item.Qty) <= 0
                                  ? 'red--text'
                                  : ''
                              "
                            >
                              {{ item.Qty }}
                            </span>
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.Qty"
                                type="number"
                              >
                              </v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          {{ item.OverAllQty }}
                        </td>
                        <td>
                          ₱
                          {{
                            formatPrice(
                              parseFloat(item.UnitCost) *
                                parseFloat(item.Qty)
                            )
                          }}
                        </td>
                        <td align="center">
                        <v-btn
                          outlined
                          color="green darken-2"
                          small
                          @click="subItems(item.SubItems, index, item.IARItemID)"
                          :disabled="item.Qty == item.IARQTY || item.editableQty == 0 || item.SubItems.length < 1"
                        >
                        <v-icon left>
                          mdi-plus
                        </v-icon>
                          <span v-if="item.SubItems.length > 0"> Items ({{item.SubItems.length}})</span>
                          <span v-else> No Items</span>
                        </v-btn>
                      </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>


            </v-form>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="green darken-2" text @click="updateIAR()">
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card>
        <v-toolbar dark max-height="65" color="green darken-2">
          <v-toolbar-title> {{ poData.IARCODE }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="clickCloseMainDialogPR()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6"
                  >Inspection and Acceptance Report</v-subheader
                >
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Entity Name</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>Davao del Norte State College</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Fund Cluster</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.FundCluster }}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Supplier</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.SupplierName }}</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">IAR No.</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.IARCODE }}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">PO No./ Date</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader
                  >{{ poData.POCode }} / {{ poData.poDate }}</v-subheader
                >
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Date Created</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.iarDate }}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold"
                  >Requisitioning Office/ Dept.</v-subheader
                >
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.OfficeDescription }}</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Invoice No.</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.InvoiceNo }}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-3">
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold"
                  >Respinsibility Center Code</v-subheader
                >
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.mopDescription }}</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Invoice Date</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader v-if="poData.DateInvoice">{{ poData.DateInvoice.join(', ') }}</v-subheader>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                v-model="formData.Lists"
                :headers="headers"
                :items="itemList"
                item-key="POItemID"
                class="elevation-1 mt-4"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Items</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="grey darken-2 mr-2"
                      dark
                      small
                      @click="editiar()"
                      class="mr-2"
                      v-if="$store.state.user.roleID == 6 || $store.state.user.roleID == 15"
                    >
                      <v-icon>mdi-clipboard-edit</v-icon>
                      Edit IAR
                    </v-btn>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      small
                      @click="viewIAR(poData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      small
                      v-if="($store.state.user.roleID == 6 || $store.state.user.roleID == 15) && itemList.length > 0"
                      @click="unpublish(poData.IARID)"
                    >
                      Unpublish
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>
                        {{ item.StockPropertyNo }}
                      </td>
                      <td>
                        {{ item.ItemName }}
                      </td>
                      <td style="max-width: 200px">
                        {{ item.ItemDescription }}
                      </td>
                      <td>{{ item.UnitMeasureDescription }}</td>
                      <td>₱ {{ formatPrice(item.UnitCost) }}</td>
                      <td>
                        {{ item.Qty }}
                      </td>
                      <td>
                        {{ item.OverAllQty }}
                      </td>
                      <td>
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.UnitCost) * parseFloat(item.Qty)
                          )
                        }}
                      </td>
                      <td align="center">
                        <v-btn
                          outlined
                          color="green darken-2"
                          small
                          @click="subItemsView(item.SubItems)"
                          :disabled="item.Qty == item.IARQTY || item.editableQty == 0 || item.SubItems.length < 1"
                          
                        >
                        <v-icon left>
                          mdi-eye
                        </v-icon>

                        <span v-if="item.SubItems.length > 0"> Items ({{item.SubItems.length}})</span>
                        <span v-else> No Items</span>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogIARVersion" max-width="500px">
      <v-card>
        <v-card-title class="text-h6"
          >Please select IAR version:</v-card-title
        >
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="6" xs="12">
              <v-icon
                dense
                x-large
                color="green darken-2"
              >mdi-file-document-multiple</v-icon><br />
              <v-btn color="green darken-2" @click="oldIAR()" text
            >Old version</v-btn>
            </v-col>
            <v-col align="center" cols="6" xs="12">
              <v-icon
                dense
                x-large
                color="green darken-2"
              >mdi-file-document-multiple-outline</v-icon><br />
              <v-btn color="green darken-2" @click="newIAR()" text
            >New version</v-btn>
            </v-col>
            <v-col align="end" cols="12">
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogIARVersion = false"
            >Cancel</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmUnpublish" max-width="500px">
      <v-card>
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="12">
              <v-icon dense x-large color="red darken-2" class="mt-4"
                >mdi-help-circle-outline</v-icon
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-title class="text-h6 d-flex justify-center"
          >Do you want to Unpublish this IAR?</v-card-title
        >
        <v-card-actions class="mt-4">
          <v-row justify="center">
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogConfirmUnpublish = false"
                >Cancel</v-btn
              >
            </v-col>
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <v-btn color="green darken-2" text @click="confirmUnpublish()"
                >Continue</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
          <v-dialog v-model="dialogSubItemsView" width="1000">
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            IAR Sub-Items
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" text @click="dialogSubItemsView = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container>
            <v-row class="">
              <v-col cols="12">
                <v-data-table
                  :headers="view_sub_headers"
                  :items="subItemList"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <span class="green--text text--darken-2"
                        >List Of Sub Items</span
                      >
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <!-- <td>
                          <v-edit-dialog
                            :return-value.sync="item.StockPropertyNo"
                            large
                          >
                            {{ item.StockPropertyNo }}
                            <template v-slot:input>
                              <v-textarea v-model="item.StockPropertyNo"> </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td> -->
                        <td>
                            {{ item.ItemName }}
                        </td>
                        <td style="max-width: 200px">
                            {{ item.ItemDescription }}
                        </td>
                        <td>
                          {{ item.UnitMeasureDescription }}
                        </td>
                        <td>
                            ₱ {{ formatPrice(item.UnitCost) }}
                        </td>
                        <td>
                          {{ item.Qty }}
                        </td>
                        <td>
                          ₱
                          {{
                            formatPrice(
                              parseFloat(item.UnitCost) *
                                parseFloat(item.Qty)
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="subItemList.length == 0" class="text-center">
                      <tr>
                        <td :colspan="headers.length" >
                          No Records Yet
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" text @click="dialogSubItemsView = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogSubItems" width="1300">
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            Edit IAR Sub-Items
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" text @click="dialogSubItems = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container>
            <v-form @submit.prevent ref="FormrefSubItems">
            <v-row class="">
              <v-col cols="12">
                <v-data-table
                  :headers="sub_headers"
                  :items="subItemList"
                  disable-pagination
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <span class="green--text text--darken-2"
                        >List Of Sub Items</span
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-2"
                        dark
                        @click="dialogAddSubItems = true"
                      >
                      <v-icon left>
                        mdi-plus
                      </v-icon>
                        Add Item
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <!-- <td>
                          <v-edit-dialog
                            :return-value.sync="item.StockPropertyNo"
                            large
                          >
                            {{ item.StockPropertyNo }}
                            <template v-slot:input>
                              <v-textarea v-model="item.StockPropertyNo"> </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td> -->
                        <td>
                          <v-edit-dialog
                            large
                            :return-value.sync="item.ItemName"
                          >
                            {{ item.ItemName }}
                          <template v-slot:input>
                              <v-textarea v-model="item.ItemName">
                              </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="max-width: 200px">
                          <v-edit-dialog
                            large
                            :return-value.sync="item.ItemDescription"
                          >
                            {{ item.ItemDescription }}
                            <template v-slot:input>
                              <v-textarea v-model="item.ItemDescription">
                              </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <!-- <v-edit-dialog
                            large
                            :return-value.sync="item.UnitMeasureDescription"
                          > -->
                          {{ item.UnitMeasureDescription }}
                          <!-- <template v-slot:input>
                              <v-autocomplete
                                v-model="item.UnitMeasureDescription"
                                item-text="UnitMeasureDescription"
                                item-value="UnitMeasureDescription"
                                :items="unitmeasureList"
                              >
                              </v-autocomplete>
                            </template>
                          </v-edit-dialog> -->
                        </td>
                        <td>
                          <v-edit-dialog
                            large
                            :return-value.sync="item.UnitCost"
                          >
                            ₱ {{ formatPrice(item.UnitCost) }}
                            <template v-slot:input>
                              <v-textarea v-model="item.UnitCost"> </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <v-edit-dialog
                            large
                            :return-value.sync="item.Qty"
                          >
                          {{ item.Qty }}
                          <template v-slot:input>
                              <v-textarea v-model="item.Qty"> </v-textarea>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          ₱
                          {{
                            formatPrice(
                              parseFloat(item.UnitCost) *
                                parseFloat(item.Qty)
                            )
                          }}
                        </td>
                        <td>
                          <v-btn 
                          small
                          color="red"
                          class="white--text"
                          :disabled="true"
                          @click="removeSubItem(item)">
                            Delete
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="subItemList.length == 0" class="text-center">
                      <tr>
                        <td :colspan="headers.length" >
                          No Records Yet
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>


            </v-form>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" text @click="dialogSubItems = false">
              Cancel
            </v-btn>
            <v-btn color="green darken-2" text @click="saveSubItems()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

          <v-dialog v-model="dialogAddSubItems" width="500">
        <v-card>
          <v-card-title class="white--text text-h5 green darken-2">
            Add New Sub item
            <v-spacer></v-spacer>
            <v-btn color="white darken-2" text @click="dialogAddSubItems = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container>
            <v-form @submit.prevent ref="FormrefSubItems">
            <v-row class="">
              <v-col cols="12">
                <v-text-field
                    dense
                    v-model="subItem.ItemName"
                    :rules="[formRules.required]"
                    label="Item Name"
                    class="my-3"
                  ></v-text-field>
                <v-text-field
                    dense
                    v-model="subItem.ItemDescription"
                    :rules="[formRules.required]"
                    label="Item Description"
                    class="mb-3"
                  ></v-text-field>

                  <v-autocomplete
                    v-model="subItem.UnitMeasure"
                    item-text="UnitMeasureDescription"
                    item-value="UnitMeasureID"
                    :rules="[formRules.required]"
                    :items="unitmeasureList"
                    return-object
                    dense
                    class="mb-4"
                    label="Unit Measure"
                  >
                  </v-autocomplete>
                  <v-text-field
                    dense
                    v-model="subItem.UnitCost"
                    :rules="[formRules.required, subItem.UnitCost > 0]"
                    label="Unit Cost"
                    class="mb-3"
                  ></v-text-field>
                  <v-text-field
                    dense
                    v-model="subItem.Qty"
                    :rules="[formRules.required, subItem.Qty > 0]"
                    label="Quatity"
                    class="mb-3"
                  ></v-text-field>
              </v-col>
            </v-row>


            </v-form>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-2" text @click="dialogAddSubItems = false">
              Cancel
            </v-btn>
            <v-btn color="green darken-2" class="white--text" @click="addSubItem()">
              Add Item
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    poData: {},
  },
  data: () => ({
    dialog: false,
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    supplier: [],
    formData: {
      Lists: [],
      InvoiceNo: null,
      DateInspected: null,
      RecieverUserID: null,
      DateInvoice: [],
      Status: null,
      IsInspected: null,
    },
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    itemList: [],
    dialogIARVersion: false,
    link: "",
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "QTY", value: "Qty", sortable: false },
      { text: "Overall QTY", value: "Amount", sortable: false },
      { text: "Amount", value: "Amount", sortable: false },
      { text: "Sub Items", value: "subItems", sortable: false, align: "center" },
    ],
     sub_headers: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "Quantity", value: "Qty", sortable: false },
      { text: "Amount", value: "Amount", sortable: false },
      { text: "Action", value: "action", sortable: false },
    ],
    view_sub_headers: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "Quantity", value: "Qty", sortable: false },
      { text: "Amount", value: "Amount", sortable: false },
      // { text: "Action", value: "action", sortable: false },
    ],
    dialogConfirmUnpublish: false,
    toUnpublishIARID: null,
    unpublishBtn: false,
    subItemList: [],
    dialogSubItemsView: false,
    dialogSubItems: false,
    subitemIndex: null,
    dialogAddSubItems: false,
    subItem: [],
    unitmeasureList: [],
    IARItemID: null
  }),
  watch: {
    poData: {
      handler(data) {
        if (data.IARID) {
          this.getItems();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    editiar() {
      this.dialog = !this.dialog;
    },
    iar() {
      this.PurposeDialog = true;
    },
    viewIAR(item) {
      this.dialogIARVersion = true;
      this.link = this.apiUrl + "/iar/report/" + item.IARID;
    },
    oldIAR() {
      window.open(this.link + "/old");
      this.dialogIARVersion = false;
    },
    newIAR() {
      window.open(this.link + "/new");
      this.dialogIARVersion = false;
    },
    clickCloseMainDialogPR() {
      this.formData.Lists = [];
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeViewOtherIAR", false);
    },

    getItems() {
      this.unpublishBtn = false;
      this.formData.Lists = [];
      let data = new FormData();
      data.append("IARID", this.poData.IARID);
      this.axiosCall("/iar/getOtherIARItems", "POST", data).then((res) => {
        this.itemList = res.data.data.result;
        this.users = res.data.data.users;
        this.ableToUnpublish();
      });
    },
    updateIAR() {
      if (this.$refs.Formref.validate()) {
        this.isLoading = true;
        let error = false;
        for (let item of this.itemList) {
          if ( parseInt(item.OverAllQty) < parseInt(item.Qty) || parseInt(item.Qty) <= 0 ) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
        } else {
        let data = new FormData();
        data.append("List", JSON.stringify(this.itemList));
        data.append("IARID", this.poData.IARID);
        data.append("InvoiceNo", this.poData.InvoiceNo);
        data.append("DateInvoice", JSON.stringify({...this.poData.DateInvoice}));
        data.append("DateInspectedStart", this.poData.DateInspectedStart);
        data.append("DateInspectedEnd", this.poData.DateInspectedEnd);
        data.append("RecieverUserID", this.poData.RecieverUserID);
        data.append("DateDelivered", JSON.stringify({...this.poData.DateDelivered}));
        data.append("DateReceivedStart", this.poData.DateReceivedStart);
        data.append("DateReceivedEnd", this.poData.DateReceivedEnd);
        data.append("Status", this.poData.Status);
        data.append("IsInspected", this.poData.IsInspected == true ? 1 : 0);
        data.append("SupplierName", this.poData.SupplierName);
        this.axiosCall("/iar/update", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
            //this.$refs.Formref.reset();
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Item Successfully Updated!";
            this.fadeAwayMessage.header = "System Message";
            this.dialog = false;
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = "System Message";
          }
        });
        }
      }
    },
        ableToUnpublish() {
      // this.unpublishBtn = false;
      //   this.itemList.forEach(item => {
      //     if (item.POID != 0 && item.PRstatus != 4) {
      //     this.unpublishBtn = true;
      //   }
      //   });
      return true;
    },
    unpublish(IARID) {
      this.dialogConfirmUnpublish = true;
      this.toUnpublishIARID = IARID;
    },
    confirmUnpublish(){
      let data = new FormData();
      data.append("IARID", this.toUnpublishIARID);
      data.append("List", JSON.stringify(this.itemList));
      this.axiosCall("/iar/unpublish", "POST", data).then((res) => {
        if(res.data.status) {
            this.toUnpublishPOID = null;
            this.dialogConfirmUnpublish = false;
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'success';
            this.fadeAwayMessage.message = 'PO successfully unpublished!';
            this.fadeAwayMessage.header = 'System Message';
            this.getItems();
            }else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'error';
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = 'System Message';
            }
      });
    },
    subItemsView(data) {
       this.subItemList = data;
       this.dialogSubItemsView = true;
    },
    subItems(data, index, IARItemID) {
       this.subItemList = data;
       this.IARItemID = IARItemID;
       this.subitemIndex = index;
       this.dialogSubItems = true;
    },
    addSubItem(){
      if (this.$refs.FormrefSubItems.validate()) {
        // this.itemList[this.IARItemID].SubItems
        this.subItemList.push(
          {
            'IARItemID': this.IARItemID,
            'StockPropertyNo': this.subItem.StockPropertyNo,
            'ItemName': this.subItem.ItemName,
            'ItemDescription': this.subItem.ItemDescription,
            'UnitMeasureID': this.subItem.UnitMeasure.UnitMeasureID,
            'UnitMeasureDescription': this.subItem.UnitMeasure.UnitMeasureDescription,
            'UnitCost': this.subItem.UnitCost,
            'Qty': this.subItem.Qty,
          });
        this.dialogAddSubItems = false;
        // console.log(this.subItemList);
        this.$refs.FormrefSubItems.reset();
      }
    },
    saveSubItems(){
      this.itemList[this.subitemIndex].SubItems = this.subItemList;
      this.subItemList = [];
      this.dialogSubItems = false;
    },
    getCategory() {
      this.axiosCall("/getCategory", "GET").then((res) => {
        //this.categoryList = res.data.data.category;
        this.unitmeasureList = res.data.data.unitmeasure;
      });
    },
    removeSubItem(item){
      let index = this.subItemList.indexOf(item);
      if (index > -1) {
        this.subItemList.splice(index, 1);
      }
    },
    remove(date, field) {
      let data = [];
      if(field == 'invoice'){
        data = this.poData.DateInvoice;
      }
      else if(field == 'delivered'){
        data = this.poData.DateDelivered;
      }
      var index = data.findIndex(x => x===date)
       data.splice(index,1);
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>